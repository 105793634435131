import React, { useEffect, useState } from 'react';
import io from 'socket.io-client';

const URL = process.env.NODE_ENV === 'production' ? 'https://socket.aledoux.eu' : 'http://localhost:8000';
const socket = io(URL); // Assurez-vous d'utiliser l'URL correcte pour votre serveur WebSocket

const MyComponent = () => {
  const [messageInput, setMessageInput] = useState('');
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [connectedUsersCount, setConnectedUsersCount] = useState(0);

  useEffect(() => {
    // Abonnement aux canaux "public" et "music"
    socket.emit('subscribe', 'music.public');
    socket.emit('subscribe', 'music.music');

    // Fonction de traitement pour le canal "public"
    const handlePublicMessage = (message) => {
      console.log('Message reçu dans le canal "public":', message);
      // Exécutez votre fonction spécifique pour le canal "public" ici
      newMessagePublic(message.data);
    };

    // Fonction de traitement pour le canal "music"
    const handleMusicMessage = (message) => {
      console.log('Message reçu dans le canal "music":', message);
      // Exécutez votre fonction spécifique pour le canal "music" ici
      newMessageMusic(message.data);
    };

    // Écoute de l'événement "usersCount"
    socket.on('usersCount', (count) => {
      setConnectedUsersCount(count);
    });

    // Écoute des messages pour chaque canal
    socket.on('message', (message) => {
      if (message.channel === 'music.public') {
        handlePublicMessage(message);
      } else if (message.channel === 'music.music') {
        handleMusicMessage(message);
      }
    });

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      // Désabonnement des canaux lors du démontage du composant
      socket.emit('unsubscribe', 'music.public');
      socket.emit('unsubscribe', 'music.music');

      // Suppression des écouteurs de messages
      socket.off('message');
    };
  }, []);

  const handleMessageSubmit = (e) => {
    e.preventDefault();

    sendMessage('music.public', { message: messageInput })

    // Réinitialiser l'entrée du message après l'envoi
    setMessageInput('');
  };

  const sendMessage = (channel, data) => {
    // Envoyer le message au serveur via le socket WebSocket
    socket.emit('message', { channel, data });
  };

  const handleKeyDown = (event) => {
    const key = event.key;
    sendMessage('music.music', key)
  };

  const newMessagePublic = (data) => {
    console.log('New msg public');
    setReceivedMessages((prevMessages) => [...prevMessages, data]);
  };

  const newMessageMusic = async (data) => {
    console.log('New msg music');

    /** 
    try {
      const context = new (window.AudioContext || window.webkitAudioContext)();
      const response = await fetch(`${process.env.PUBLIC_URL}/sounds/ah.mp3`);
      const arrayBuffer = await response.arrayBuffer();
      const buffer = await context.decodeAudioData(arrayBuffer);
      const source = context.createBufferSource();
      source.buffer = buffer;
      source.connect(context.destination);
      source.start(0);
    } catch (error) {
      console.error('Erreur lors de la lecture du fichier audio :', error);
    }
    */
  };

  return (
    <div>
      <h1>Channel Subscriber Component</h1>

      <h2>Nombre de personnes connectées : {connectedUsersCount}</h2>

      <form onSubmit={handleMessageSubmit}>
        <input
          type="text"
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
        />
        <button type="submit">Envoyer</button>
      </form>
      <div>
        <h2>Messages reçus :</h2>
        <ul>
          {receivedMessages.map((data, index) => (
            <li key={index}>{data.message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MyComponent;
